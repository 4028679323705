import * as React from "react"
import { Helmet } from "react-helmet";

const Seo = ({ currentPath }) => {
	return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>Genblock Capital</title>
            <meta name="title" content="Genblock Capital" />
            <meta name="description" content="Genblock Capital invests exclusively in blockchain and cryptocurrency, with a focus on decentralized finance." />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Genblock Capital" />
            <meta property="og:description" content="Genblock Capital invests exclusively in blockchain and cryptocurrency, with a focus on decentralized finance." />
            <meta property="og:image" content="https://genblock.capital/static/Genblock-og-image-e30711df260be367699c86ae18c08e13.jpg" />
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:title" content="Genblock Capital" />
            <meta property="twitter:description" content="Genblock Capital invests exclusively in blockchain and cryptocurrency, with a focus on decentralized finance." />
            <meta property="twitter:image" content="https://genblock.capital/static/Genblock-og-image-e30711df260be367699c86ae18c08e13.jpg"></meta>
            <style>{`body { margin: 0; padding: 0; } * { font-family: 'Roboto Mono', monospace; }`}</style>
        </Helmet>
	)
}

export default Seo
