import * as React from "react"
import styled from 'styled-components'
import { Canvas, useFrame } from 'react-three-fiber'

const StyledBackground = styled.aside`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;

    height: 100vh;

    z-index: -1;
`

function Box() {
    // This reference will give us direct access to the mesh
    const mesh = React.useRef()

    // Rotate mesh every frame, this is outside of React without overhead
    useFrame(() => {
        mesh.current.rotation.x = mesh.current.rotation.y += 0.003
    })
    return (
        <lineSegments
            ref={mesh}
            position={[0, 0, 0]}>
                <boxBufferGeometry args={[3.5, 3.5, 3.5]} />
                <lineBasicMaterial color={'lightgrey'} linewidth={1} wireframe={true}/>
        </lineSegments>
    )
}   

const BoxAnimation = () => {
	return (
        <StyledBackground>
            <Canvas>
                <Box/>
            </Canvas>
        </StyledBackground>
	)
}

export default BoxAnimation
