import * as React from "react"
import { Link } from 'gatsby'
import styled from 'styled-components'

import Logo from './Logo'

const StyledMain = styled.main`
	min-height: 100vh;
	max-width: 1200px;
	display: grid;
	grid-template-columns: auto;
	grid-template-rows: auto 1fr auto;
	margin: 0 auto;
`

const StyledHeader = styled.header`
	display: flex;
	justify-content: space-between;
`

const StyledNav = styled.nav`
	margin: 40px 64px 0 0;

    & > a:last-child {
		margin: 0 !important;
	}

	@media (max-width: 656px) {
        margin: 7.733vw 6.400vw 0 0;
		display: flex;
        justify-content: flex-end;
        flex-direction: column;
        text-align: right;
	}
`

const linkStyling = `
    margin: 0 16px 0 0;
    padding-bottom: 8px;

	font-size: 16px;
	font-weight: 300;
    color: black;
	text-decoration: none;
    border-bottom: 1px solid transparent;

    transition: all .1s ease-in;

	&:hover {
		border-bottom: 1px solid black;
        padding-bottom: .5vw;
	}

    @media (max-width: 656px) {
		font-size: 3.200vw;
        width: 100%;
        margin-right: 2.133vw;
	}
`

const StyledPageLink = styled(Link)`${ linkStyling }`
// const StyledContactLink = styled.a`${ linkStyling }`

const StyledFooter = styled.footer`
	text-align: center;
`

const StyledCopyright = styled.p`
	font-size: 16px;
	font-weight: 300;
	margin-bottom: 24px;

	@media (max-width: 656px) {
		font-size: 3.692vw;
		margin-bottom: 7.385vw;
	}
`

const LayoutComponent = ({ children, root }) => {
	return (
		<>
			<StyledMain>
                <StyledHeader>
					<Logo root={root}/>
					<StyledNav>
						<StyledPageLink to={'/about'}>about</StyledPageLink>
						<StyledPageLink to={'/portfolio'}>portfolio</StyledPageLink>
						{/* <StyledContactLink href={'mailto:info@genblock.capital'}>contact us</StyledContactLink> */}
					</StyledNav>
				</StyledHeader>
                { children }
				<StyledFooter>
					<StyledCopyright>
						© 2021 Genblock Capital
					</StyledCopyright>
				</StyledFooter>
			</StyledMain>
		</>
	)
}

export default LayoutComponent
